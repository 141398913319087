import React from 'react'
import Headroom from 'react-headroom'
import { Logo, Navigation } from 'components'
import { Header } from './styles'

const HeaderComponent = props => {
  return (
    <Headroom
      style={{
        background: 'transparent',
        height: 65
      }}>
      <Header>
        <div onClick={props.onNavPress.bind(null, 'head')}>
          <Logo />
        </div>
        <Navigation onNavPress={props.onNavPress} />
      </Header>
    </Headroom>
  )
}

export default HeaderComponent
