export default {
    GREEN_BASE: '#16B2CE',
    GREEN_LIGHT: '#0c879d',
    GREEN_DARK: '#00cbef',

    BLUE_BASE: '#439de6',

    GREY_BASE: '#626262',
    GREY_GREEN: '#f0f8f7',
    GREY_LIGHT: '#dadada',
    GREY_XLIGHT: '#ececec',

    WHITE: '#fff',
    BLACK: '#000',

    YELLOW: '#fffe00',
    RED: 'tomato'
}