import styled from 'styled-components'
import { Colors, Spacings } from 'config'

export const Home = styled.div`
  width: 100%;
  color: ${Colors.WHITE};

  a,
  a:visited,
  a:link {
    color: ${Colors.YELLOW};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`

export const Icon = styled.div`
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -40px;
  left: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  font-size: 20rem;
  margin-left: ${({ yellow }) => (yellow ? '60px' : 0)};
  z-index: ${({ yellow }) => (yellow ? 0 : 0)};
  color: ${({ yellow }) => (yellow ? Colors.YELLOW : 'white')};
  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    margin-left: ${({ yellow }) => (yellow ? '30px' : 0)};
    font-size: 30rem;
  }
`
export const InnerIcon = styled.div`
  margin-left: -40px;
  margin-top: 60px;
  transform: translateZ(50px);
  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    margin-left: -100px;
    margin-top: 60px;
    transform: translateZ(50px);
  }
`

export const Bio = styled.div`
  margin: 0;
  padding: 80px 0px;
  width: calc(100% - ${Spacings.LARGE * 2}px);
  font-size: 1.1rem;
  text-transform: lowercase;
  max-width: 700px;
  /* display: none; */
  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    font-size: 1.4rem;
  }
`
export const Lineup = styled.div`padding: 0 20px;`

export const Gallery = styled.div`margin: 40px 0;`

export const Content = styled.div`margin-top: -65px;`
export const VideoBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
export const Mixes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`
export const Mix = styled.div`
  position: relative;
  width: 50%;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    transition: transform .1s ease, opacity .1s ease;
  }
  background: black;
  overflow: hidden;
  z-index: 0;

  :hover {
    div {
      opacity: 1;
      transform: scale(1);
    }
    img {
      opacity: 0.4;
      transform: scale(1.5);
    }
  }

  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    width: 25%;
  }
`

export const MixTitle = styled.div`
  position: absolute;
  z-index: 2;
  color: white;
  padding: 0 20px;
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: lowercase;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transform: scale(1.1);
  transition: transform .1s ease, opacity .1s ease;
`

export const InstaFeed = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`
export const Image = styled.div`
  position: relative;
  width: 50%;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    transition: transform .1s ease, opacity .1s ease;
  }
  background: black;
  overflow: hidden;
  z-index: 0;

  :hover {
    img {
      /* opacity: 0.4; */
      transform: scale(1.1);
    }
  }

  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    width: 25%;
  }
`

export const AudioPlayer = styled.div`
  position: fixed;
  bottom: -3px;
  width: 100%;
  z-index: 99;
  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    bottom: -3px;
  }
`

export const ContactLabel = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 700;
  color: grey;
`
export const Socials = styled.div`
  padding: 10px;
  img {
    height: 20px;
  }
  a {
    border: 0;
    text-decoration: none;
    margin: 0 12px;
  }
  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    img {
      height: 30px;
    }
  }
`
