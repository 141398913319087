import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { App } from 'components'
import { Home } from 'screens'
import 'assets/styles/app.css'

class AppRouter extends Component {
  render () {
    return (
      <Router>
        <App>
          <Route exact path='/' component={Home} />
        </App>
      </Router>
    )
  }
}

export default AppRouter
