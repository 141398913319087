import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { App } from './styles';

class AppComponent extends Component {
  
  render() {
    return (
      <App>
        {this.props.children}
      </App>
    );
  }
}

export default withRouter(AppComponent);
