import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Logo } from './styles';

class AppComponent extends Component {
  
  render() {
    return (
      <Logo>
        rog<span className='logo__stripe'>A</span>n<span className='logo__stripe'>-</span>j
      </Logo>
    );
  }
}

export default withRouter(AppComponent);
