import styled from 'styled-components'
// import { Spacings, Colors } from 'config'

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 1000;
`
