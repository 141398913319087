import styled from 'styled-components'
import { Colors } from 'config'

export const Logo = styled.p`
  padding: 0;
  margin: 0;
  margin-left: 0px;
  font-size: 2.4rem;
  color: ${Colors.WHITE};
  letter-spacing: -2px;

  .logo__stripe {
    color: ${Colors.YELLOW};
  }
`
