export default {
    SMALL: 8,
    MEDIUM: 16,
    LARGE: 32,
    XLARGE: 60,

    FONT: {
        BODY: 16,
        TITLE: 20,
        HEADER: 32
    },

    SCREEN: {
        MOBILE: 320,
        TABLET: 750,
        DESKTOP: 1024,
        DESKTOP_LARGE: 1200,
    }
}