import React from 'react'
import { Section } from './styles'

const SectionComponent = props => {
  return (
    <Section
      active={props.active}
      pointerEvents={props.pointerEvents}
      color={props.color}>
      {props.children}
    </Section>
  )
}

SectionComponent.defaultProps = {
  color: 'transparent',
  pointerEvents: true
}

export default SectionComponent
