import styled from 'styled-components'
import { Spacings, Colors } from 'config'

export const Navigation = styled.div`
  /* justs */
  position: absolute;
  top: 65px;
  height: auto;
  /* left: auto;
  bottom: 0px; */
  color: ${Colors.WHITE};
  /* display: flex; */
  width: 100%;
  background: black;
  z-index: 90;

  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    /* display: flex;
    left: ${Spacings.LARGE}px;
    top: 50%;
    transform: translateY(-80px); */
    z-index: 1000;
    /* height: 155px; */
    /* width: 0; */
    /* background: transparent; */
  }
`

export const Nav = styled.ul`
  margin: 6px 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  li {
    list-style: none;
    text-align: left;
    font-size: 1.4em;
  }

  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    li {
      font-size: 1.4em;
      margin-bottom: ${Spacings.SMALL}px;
    }
  }
`
