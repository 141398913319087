import React from 'react'
import { NavItem } from './styles'

const NavItemComponent = props => {
  return (
    <NavItem onClick={props.onClick.bind(null, props.label.toLowerCase())}>
      {props.label}
    </NavItem>
  )
}

NavItemComponent.defaultProps = {
  label: '',
  onClick: () => {}
}

export default NavItemComponent
