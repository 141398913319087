import React from 'react'
import { NavItem } from 'components'
import { Navigation, Nav } from './styles'

const NavigationComponent = props => {
  return (
    <Navigation>
      <Nav>
        <NavItem label='mixes' onClick={props.onNavPress} />
        <NavItem label='bio' onClick={props.onNavPress} />
        <NavItem label='contact' onClick={props.onNavPress} />
      </Nav>
    </Navigation>
  )
}

NavigationComponent.defaultProps = {
  onNavPress: () => {}
}

export default NavigationComponent
