import React, { useState, useEffect } from 'react'
import { useWindowSize } from 'utils'
import axios from 'axios'
import VanillaTilt from 'vanilla-tilt'
import lozad from 'lozad'
import { Element, scroller } from 'react-scroll'
import Particles from 'react-particles-js'
import ReactGA from 'react-ga'
import { Header, Section } from 'components'
import { Spacings, Colors } from 'config'
import {
  Bio,
  Content,
  Home,
  Icon,
  Mixes,
  Mix,
  MixTitle,
  AudioPlayer,
  ContactLabel,
  Lineup,
  InnerIcon,
  Socials,
  Image,
  Gallery,
  InstaFeed
} from './styles'
import MixcloudImg from 'assets/images/mixcloud.svg'
import Instagram from 'assets/images/insta.svg'
import Facebook from 'assets/images/fb.svg'

const HomeComponent = () => {
  const windowSize = useWindowSize()
  const [ mixes, setMixes ] = useState([])
  const [ currentSlug, setCurrentSlug ] = useState(null)
  const [ widgetHeight, setWidgetHeight ] = useState(120)
  const [ instaFeed, setInstaFeed ] = useState([])

  useEffect(
    () => {
      if (widgetHeight !== 60 && windowSize.width < Spacings.SCREEN.TABLET) {
        setWidgetHeight(60)
      } else if (
        widgetHeight !== 120 &&
        windowSize.width > Spacings.SCREEN.TABLET
      ) {
        setWidgetHeight(120)
      }
    },
    [ windowSize ]
  )

  useEffect(() => {
    fetchMixes()
    fetchInsta()
    // Setup Tilting
    VanillaTilt.init(document.querySelector('.iconYellow'), {
      max: 15,
      speed: 800,
      reverse: true,
      'full-page-listening': true
    })

    // Setup Lazy Loading
    const observer = lozad() // lazy loads elements with default selector as '.lozad'
    observer.observe()

    // Tracking
    ReactGA.initialize('UA-143449129-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const onNavPress = section => {
    scroller.scrollTo(section, {
      duration: 500,
      smooth: true,
      // containerId: 'content',
      offset: 0
    })
  }

  const fetchMixes = async () => {
    const response = await axios({
      method: 'GET',
      url: `https://api.mixcloud.com/rogan-j/cloudcasts/?metadata=1`,
      validateStatus: status => {
        return true
      },
      timeout: 7000
    })
    if (response.status === 200) {
      onMixPress(response.data.data[0])
      setMixes(response.data.data)
      // console.log('__MIXES__', response.data)
    }
  }

  const fetchInsta = async () => {
    const response = await axios({
      method: 'GET',
      url: `https://www.instagram.com/roganj_audio/?__a=1`,
      validateStatus: status => {
        return true
      },
      timeout: 7000
    })
    if (response.status === 200) {
      // console.log('__instra__', response.data)
      setInstaFeed(
        response.data.graphql.user.edge_owner_to_timeline_media.edges
      )
    }
  }

  const onMixPress = mix => {
    const html = `<iframe
      title='mixcloud-iframe'
      id='mixcloud-iframe'
      width='100%'
      loading='lazy'
      height=${widgetHeight}
      src='https://www.mixcloud.com/widget/iframe/?feed=https%3A%2F%2Fwww.mixcloud.com%2Frogan-j%2F${mix.slug}%2F&amp;hide_cover=1&amp;hide_artwork=0&amp;autoplay=1'
      frameborder='0'
    />`

    setCurrentSlug({ __html: html })
  }

  return (
    <Home>
      <Header onNavPress={onNavPress} />
      <Particles
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0
        }}
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 1202.559045649142
              }
            },
            color: {
              value: '#000000'
            },
            shape: {
              type: 'triangle',
              stroke: {
                width: 1,
                color: Colors.YELLOW
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 10,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: false,
              distance: 500,
              color: '#ffffff',
              opacity: 0.4,
              width: 2
            },
            move: {
              enable: true,
              speed: 6,
              direction: 'top',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 721.5354273894853
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'repulse'
              },
              onclick: {
                enable: true,
                mode: 'repulse'
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 0.5
                }
              },
              bubble: {
                distance: 400,
                size: 4,
                duration: 0.3,
                opacity: 1,
                speed: 3
              },
              repulse: {
                distance: 194.898530952323,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        }}
      />
      <Content>
        <div id='content'>
          <Element name='head'>
            <Section color={'black'} pointerEvents={false}>
              <Icon yellow className='iconYellow'>
                A
                <InnerIcon>
                  <Icon className='icon'>A</Icon>
                </InnerIcon>
              </Icon>
            </Section>
          </Element>
          <Element name='mixes'>
            <Section color={'tomato'}>
              <ContactLabel>listen:</ContactLabel>
              <Mixes>
                {mixes.map(
                  (mix, i) =>
                    i <= 7 && (
                      <Mix key={mix.name} onClick={onMixPress.bind(null, mix)}>
                        <MixTitle>{mix.name}</MixTitle>
                        <img
                          alt={mix.name}
                          className='lozad'
                          loading='lazy'
                          src={mix.pictures['640wx640h']}
                        />
                      </Mix>
                    )
                )}
              </Mixes>
            </Section>
          </Element>
          <Section color={'purple'}>
            <Element name='bio' />
            <Bio>
              <ContactLabel>who:</ContactLabel>
              Growing up the middle of UK. <br />Josh(Rogan) was exposed to all
              sorts of flavours of underground dance. Moving through happy
              hardcore / hard house / trance to techno. VJing (Hybrid Visuals)
              for some big name artists, at hard dance events such as AntiWorld,
              Storm and Riot. Moving attention to music, playing out on 1210’s
              at local bars in the midlands. Fast forward a decade the passion
              has been reignited with a new generation of technology, and
              Rogan-J has risen. A mixture of Tech / Progressive / Deep /
              Electro House to pumping underground Techno!
            </Bio>
            <Lineup>
              <ContactLabel>lineup:</ContactLabel>
              currently you can check rogan-j <br />playing frequently at
              corbett & claude in BRISBANE.
            </Lineup>
            <Gallery>
              <ContactLabel>look:</ContactLabel>
              <InstaFeed>
                {instaFeed.map(
                  (image, i) =>
                    i <= 7 && (
                      <Image key={image.node.display_url}>
                        <img
                          alt={image.name}
                          loading='lazy'
                          src={image.node.display_url}
                        />
                      </Image>
                    )
                )}
              </InstaFeed>
            </Gallery>
          </Section>
          <Element name='contact'>
            <Section color={'#252525'} active>
              <h2>
                <ContactLabel>email:</ContactLabel>{' '}
                <a href='mailto:listen@roganj.com'>listen@roganj.com</a>
              </h2>
              <h2>
                <ContactLabel>chat:</ContactLabel>
                <a href='tel:0478196213'>0478196213</a>
              </h2>
              <h2>
                <ContactLabel>coffee:</ContactLabel>🌴 byron bay 🌴 <br /> 🌴
                mullumbimby 🌴
              </h2>
              <h2>
                <ContactLabel>socials:</ContactLabel>
                <Socials>
                  <a
                    href='https://www.mixcloud.com/rogan-j/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img src={MixcloudImg} alt='mixcloud' />
                  </a>
                  <a
                    href='https://www.instagram.com/roganj_audio/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img src={Instagram} alt='instagram' />
                  </a>
                  <a
                    href='https://www.facebook.com/roganjaudio/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img src={Facebook} alt='facebook' />
                  </a>
                </Socials>
              </h2>
            </Section>
          </Element>
        </div>
      </Content>
      <AudioPlayer>
        {currentSlug && <div dangerouslySetInnerHTML={currentSlug} />}
      </AudioPlayer>
    </Home>
  )
}

export default HomeComponent
