import styled from 'styled-components'
// import { Spacings, Colors } from 'config'

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh + 60px);
  background: transparent;
  pointer-events: ${({ pointerEvents }) => (!pointerEvents ? 'none' : 'all')};
  position: ${({ active }) => active && 'relative'};
  /* z-index: ${({ active }) => active && '30'}; */
`
