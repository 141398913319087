import styled from 'styled-components'
import { Spacings, Colors } from 'config'

export const NavItem = styled.li`
  text-align: left;
  font-size: 1.4em;
  margin-bottom: ${Spacings.SMALL}px;
  background: rgba(0, 0, 0, 0.7);
  padding: 6px 12px;
  cursor: pointer;
  transition: color .5s ease, background .25s ease;
  display: inline-flex;
  &:hover {
    color: ${Colors.YELLOW};
    background: rgba(0, 0, 0, 0.9);
  }

  @media (min-width: ${Spacings.SCREEN.TABLET}px) {
    display: block;
  }
`
